<!--suppress ES6MissingAwait -->
<template>
  <div class="">
    <div class="container-fluid">
      <MainProductsNavHeader
        @update:quickSearchOrderNumber="handleQuickSearchOrderNumber"
        @update:quickSearchOrderRootId="handleQuickSearchOrderRootId"
        @update:customSearchText="handleCustomSearchText"
        @update:loadQuickSearch="handleLoadQuickSearch"
        @advancedSearchClicked="handleAdvancedSearchClicked"
      />
      <template v-if="showOrdersNavbar && informationLoaded">
        <div class="row fixed-body-under-header overflow-y-hidden">
          <router-view v-slot="{ Component }">
            <component
              :is="Component"
              :load-quick-search="localLoadQuickSearch"
              v-model:openViewManagement="openViewManagement"
              @update:loadQuickSearch="handleUpdateLoadQuickSearch"
            />
          </router-view>
        </div>
      </template>
    </div>
    <template v-if="!showOrdersNavbar && informationLoaded">
      <div class="pl-2">
        <router-view v-slot="{ Component }">
          <component
            :is="Component"
            :load-quick-search="localLoadQuickSearch"
            v-model:openViewManagement="openViewManagement"
            @update:loadQuickSearch="handleUpdateLoadQuickSearch"
          />
        </router-view>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, onMounted, computed, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import MainProductsNavHeader from "@/components/MainProductsNavHeader";
import RichTextEditorWrapper from "@/components/syncfusionWrappers/RichTextEditorWrapper";
import LogoModal from "@/components/uIElements/LogoModal";

import getOrdersGridLink from "@/utils/navigation/getOrdersGridLink.js";

import { TrulyEsqProductRootId, DesqProductRootId } from "@/lib/settings";

export default {
  name: "NavHome",
  components: {
    MainProductsNavHeader,
    LogoModal,
    RichTextEditorWrapper,
  },
  props: {
    informationLoaded: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const isSubmitted = ref(null);

    // Vuex
    const store = useStore();
    // State
    const loggedInUser = computed(() => store.state.auth.loggedInUser);
    const isOnOrderTab = computed(() => store.state.srchs.isOnOrderTab);
    // Getters
    const getUserConfigSetting = computed(() => store.getters.getUserConfigSetting);
    const getMergedUserConfigSetting = computed(() => store.getters.getMergedUserConfigSetting);
    const getGlobalSelectedProductRootId = computed(() => store.getters.getGlobalSelectedProductRootId);
    const canLoggedInUserAccessTrulyEsq = computed(() => store.getters.canLoggedInUserAccessTrulyEsq);
    const canLoggedInUserAccessDesq = computed(() => store.getters.canLoggedInUserAccessDesq);
    // Actions
    const updateUserConfigSetting = async (userConfigSettingToUpdate) => await store.dispatch("updateUserConfigSetting", userConfigSettingToUpdate);

    const localQuickSearchOrderNumber = ref("");
    const localQuickSearchOrderRootId = ref(0);
    const localCustomSearchText = ref("");
    const localLoadQuickSearch = ref({ load: false, orderRootId: 0, orderNumber: "", text: "" });
    const openViewManagement = ref(false);

    // Computed
    const isOnLinks = computed(() => selectedProduct.value === "links");
    const isOnTrulyEsq = computed(() => selectedProduct.value === "trulyesq");
    const isOnDesq = computed(() => selectedProduct.value === "desq");
    const selectedProduct = computed(() => {
      const hostName = window.location.host ?? "";
      const fullPath = route.fullPath.toLowerCase();
      if (hostName.includes("links") || fullPath.includes("links")) {
        return "links";
      }
      if (hostName.includes("truly-esq") || hostName.includes("trulyesq") || fullPath.includes("truly-esq") || fullPath.includes("trulyesq")) {
        return "trulyesq";
      }
      return "desq";
    });
    const inOrderEditor = computed(() => route.path.toLowerCase().includes("truly-esq/order/") || route.path.toLowerCase().includes("d-esq/order/"));
    const showOrdersNavbar = computed(
      () => !isOnLinks.value && !inOrderEditor.value && ((canLoggedInUserAccessTrulyEsq.value && isOnTrulyEsq.value) || (canLoggedInUserAccessDesq.value && isOnDesq.value))
    );

    // Functions
    function handleQuickSearchOrderNumber(value) {
      localQuickSearchOrderNumber.value = value;
    }
    function handleQuickSearchOrderRootId(value) {
      localQuickSearchOrderRootId.value = value;
    }
    function handleCustomSearchText(value) {
      localCustomSearchText.value = value;
    }
    function handleLoadQuickSearch({ orderRootId, orderNumber, text }) {
      localLoadQuickSearch.value = { load: true, orderRootId, orderNumber, text };
    }
    function getStringConfigSetting(productRootId, settingName) {
      return String(getUserConfigSetting.value({ ProductRootId: productRootId, SettingName: settingName }) ?? "");
    }
    function handleAdvancedSearchClicked() {
      openViewManagement.value = true;
    }

    function handleUpdateLoadQuickSearch() {
      localLoadQuickSearch.value = { load: false, orderRootId: 0, orderNumber: "", text: "" };
    }

    onMounted(async () => {
      await nextTick();
      const productRootId = getGlobalSelectedProductRootId.value;
      switch (productRootId) {
        case TrulyEsqProductRootId:
        case DesqProductRootId:
          await router.push(getOrdersGridLink(productRootId)).catch(() => {
            console.error("Unable to open orders grid");
          });
          break;
        default:
          await router.push({ path: "/links" }).catch(() => {
            console.error("Unable to open links");
          });
          break;
      }
    });

    return {
      showOrdersNavbar,
      isSubmitted,
      openViewManagement,
      isOnOrderTab,
      localQuickSearchOrderNumber,
      localQuickSearchOrderRootId,
      localCustomSearchText,
      localLoadQuickSearch,

      loggedInUser,
      getMergedUserConfigSetting,

      updateUserConfigSetting,
      getStringConfigSetting,
      handleAdvancedSearchClicked,
      handleQuickSearchOrderNumber,
      handleQuickSearchOrderRootId,
      handleCustomSearchText,
      handleLoadQuickSearch,
      handleUpdateLoadQuickSearch,
    };
  },
};
</script>

<style scoped>
.overflow-y-hidden {
  overflow-y: hidden !important;
}
</style>
